import axios from "@/common/interceptors.service";
import ApiService from "@/common/api.service";

export default {
  getTeacherExams(data) {
    return ApiService.query("/teacher/exams", data);
  },
  getCompanyExams(data) {
    return ApiService.query("/company/exams", data);
  },
  createCompany(data) {
    return ApiService.post("/company", data);
  },
  updateCompany(id, data) {
    return ApiService.patch(`/company/${id}`, data);
  },
  deleteCompany(id) {
    return ApiService.delete(`/company/${id}`);
  },
  teacherJoinCompany(invitationCode) {
    return ApiService.patch(`/company/join/${invitationCode}`);
  },
  listCompany(data) {
    return ApiService.query("/company", data);
  },
  listCompanyClasses(id, data) {
    return ApiService.query(`/company/${id}/session-classes`, data);
  },
  getCompanyClasses(id, data) {
    return ApiService.query("company/session-classes", data);
  },
  getCompanyInfo() {
    return ApiService.query("company/profile");
  },
  getCompanyTransactions(data) {
    return ApiService.query("company/coin-transactions", data);
  },
  adminGetCompanyTransactions(id, data) {
    return ApiService.query(`admin/companies/${id}/coin-transactions`, data);
  },
  companySetTeacher(data) {
    return ApiService.patch("/company/toggle-teacher", data);
  },
  getUsers(data) {
    return ApiService.query("company/users", data);
  },
  listCompanyTeachers(id, data) {
    return ApiService.query(`/company/${id}/teachers`, data);
  },
  listCompanyExams(id, data) {
    return ApiService.query(`/company/${id}/exams`, data);
  },
  companyGivePro(userId) {
    return ApiService.post(`company/users/${userId}/assign-pro`);
  },
  companyGiveCoins(userId, data){
    return ApiService.post(`company/users/${userId}/give-coins`, data);
  },
  adminGetCompanyInfo(id) {
    return ApiService.query(`admin/companies/${id}`);
  },
  giveCoins(id, data) {
    return ApiService.post(`admin/companies/${id}/give-coins`, data);
  },
  addUsers(companyId, data) {
    return ApiService.post(`admin/companies/${companyId}/add-users`, data);
  },
  deductCoins(uerId, data) {
    return ApiService.post(`admin/companies/${uerId}/deduct-coins`, data);
  },
  adminGivePro(companyId, data) {
    return ApiService.post(`admin/companies/${companyId}/give-pro`, data);
  },
  adminDeductPro(companyId, data) {
    return ApiService.post(`admin/companies/${companyId}/recover-pro`, data);
  }
};
